import React from 'react';

import BlogArticle from 'components/blog/blogArticle';
import { authorKalina as author } from 'data/authors';
import styled from 'styled-components';
import img from 'img/blog/low-conversation-rate.png';

const Container = styled.div`
  h3,
  h2 {
    font-weight: 700;
    letter-spacing: 0.01em;
    margin: 2em 0 0.8em;
    line-height: 2rem;
  }
  h3 {
    font-size: 1.1rem;
  }
  h2 {
    font-size: 1.5rem;
  }
  h4 {
    color: #777;
    font-weight: 700;
    font-size: 1rem;
  }
`;

const Content = () => {
  return (
    <Container>
      <p>
        Have you noticed a sudden drop in your conversion rate or has it been low to begin with?
        Fortunately, there are ways to fix it - provided that you identify the cause first. In fact,
        there are many possible explanations for a low conversion rate - and here are the 5 most
        common issues that you’re most likely struggling with as well.
      </p>
      <p>
        Before digging into the issues you might be having with your site, it’s worth keeping in
        mind that “conversion” simply describes the desired action that your website visitor
        completes. The conversion rate, on the other hand, is the percentage of users who actually
        take that desired action. Naturally, the goal is to have this rate as high as possible, as
        it means that your visitors are filling in contact forms, requesting a quote, or making a
        purchase - just to name a few. This is essentially what keeps your sales team busy, and your
        business growing.
      </p>
      <p>
        A typical website conversion rate is about 2-3% on average. It might not seem like much, but
        for many sites out there is more than enough. In fact, the only issue with conversion rates
        is that they can be affected by a number of factors - including page design, its layout, and
        content. Not to mention that it can also change a lot, which means there’s no need to beat
        yourself up if you suddenly notice a lower conversion rate.
      </p>
      <p>
        Instead, it might be helpful to be aware of the common reasons behind low conversion rates,
        and be prepared to reevaluate certain elements of your website. And that’s precisely why
        we’re going to cover those reasons, along with potential solutions. Let’s dive in!
      </p>
      <h2>5 reasons why your conversion rate is low</h2>
      <h3>#1 Your site is not optimized for search engines.</h3>
      <p>
        Keyword optimisation might sound slightly old school, but it’s still extremely important for
        any website out there. Basically, well-optimized sites are able to rank higher in search
        engines, which helps them appear almost immediately when the users are in search of related
        content, products or services. This indicates that if you’re neglecting SEO, your website
        won’t show up when potential visitors need it. In turn, low traffic means fewer chances of
        conversion.
      </p>
      <p>
        What can you do about it? First, check how optimized your website really is, and what
        keywords you should be ranking for. There are plenty of tools that can help you with that,
        including{' '}
        <a href="https://senuto.com" target="_blank" rel="noopener noreferrer">
          Senuto
        </a>{' '}
        . With such software, you can easily identify any issues you might be dealing with in terms
        of SEO, and get to the bottom of them to rank higher in Google.
      </p>
      <h3>#2 The user experience is not the best.</h3>
      <p>
        Let’s assume that visitors arrive at your site, though. Now, if they find your website
        confusing, or if the content doesn’t match their expectations - your conversion rate will
        still be quite low. Sadly, website owners often forget that their site is supposed to serve
        the visitors, and assume that everything is clear for them. In many cases, they couldn’t be
        more wrong.
        <a href="/blog/improving-customer-experience/" target="_blank" rel="noopener noreferrer">
          Customer experience
        </a>{' '}
        can’t be ignored.
      </p>
      <p>
        If you want to check whether the visitors behave on your site exactly as you think they do,
        you should definitely take advantage of{' '}
        <a href="https://livesession.io" target="_blank" rel="noopener noreferrer">
          LiveSession
        </a>{' '}
        and analyse the user session recordings carefully. This can help you optimize your website
        in terms of UX in the long run.
      </p>
      <h3>#3 Your website is not mobile-friendly.</h3>
      <p>
        Speaking of optimisation - it’s also worth optimising your website for mobile devices. Since
        both smartphone and mobile web usage continue to grow, the Internet has now become a truly
        “mobile-first” experience. Actually, mobile devices already account for more than half of
        the worldwide web traffic nowadays, while their users represent almost half of the
        population. Given these numbers, having at least a responsive design of your website should
        be your priority - otherwise, you might be easily missing out on mobile conversions. Also,
        don’t forget that many users go through multiple channels before they make a decision, so
        having well-designed desktop and mobile versions of your site can definitely help them
        throughout the process. Take{' '}
        <a
          href="https://search.google.com/test/mobile-friendly"
          target="_blank"
          rel="noopener noreferrer"
        >
          Google’s Mobile-Friendly Test
        </a>{' '}
        to see whether that’s the case.
      </p>
      <h3>#4 Your CTAs are not clear (or attractive) enough.</h3>
      <p>
        To put it simply: the better the CTA, the higher the conversion rates. Oftentimes, the CTAs
        are generic and, frankly, quite boring (such as <i>Buy Now</i> or <i>Download for free</i>).
        In order for it to actually encourage more conversions, your CTA needs to be unique and
        relevant to the desired action.{' '}
      </p>
      <p>
        At the same time, you can’t ask too much. The more CTAs you use, the fewer the chances that
        your visitors actually do something about them - especially if you use words such as{' '}
        <i>Buy</i>, <i>Share</i>, <i>Support</i> or <i>Start</i>, as they indicate that some effort
        (or money) will be involved in the process. You might want to consider <i>Get</i>,{' '}
        <i>Discover</i>, <i>Earn</i> or <i>Check out</i> instead, and also use{' '}
        <a href="https://livesession.io" target="_blank" rel="noopener noreferrer">
          LiveSession
        </a>{' '}
        to see how willing are your visitors to click on such CTAs.{' '}
      </p>
      <h3>#5 You’re trying too much. </h3>
      <p>
        Yes, you’ve heard that one right. There are plenty of{' '}
        <a
          href="https://livesession.io/blog/top-6-tools-for-improving-conversion-in-saas/"
          target="_blank"
          rel="noopener noreferrer"
        >
          online tools you can use to make your site more conversion-friendly
        </a>
        , but you have to make sure you use them wisely. Take popups, as an example. You can easily
        overuse them to the point that you’re ruining your conversion rate instead of improving it.
      </p>
      <p>
        It’s mostly because some popups can be really intrusive, especially if you display them
        every few seconds and keep your visitors from actually learning something about your
        website. As a result, they can easily become frustrated and exit your website immediately
        rather than leaving their e-mail address and staying in touch with you, for instance.
      </p>
      <p>
        How to take advantage of popups - and other solutions that aim to boost conversions like{' '}
        <a href="https://chatbotize.com" target="_blank" rel="noopener noreferrer">
          chatbots
        </a>
        , for that matter? It might help to identify where your users drop off first, and then
        tailor all your messages and incentives to the desired user flow. Keep the number of such
        messages reasonable, though - bombarding your visitors can definitely do more harm than
        good.
      </p>
      <h2>Start improving your conversion rate now</h2>
      <p>
        Still not convinced that optimising your site can work wonders for your conversion rate?
        Well, take into account that{' '}
        <a
          href="https://econsultancy.com/74-of-businesses-believe-user-experience-is-key-for-improving-sales/"
          target="_blank"
          rel="noopener noreferrer"
        >
          74% of business owners
        </a>{' '}
        saw an increase in sales after improving UX. The key here is to take a closer look at your
        site and make data-driven decisions, as having enough data evidence is the best way to spot
        any issues and improve your conversion rate in the long run. Take advantage of what
        <a href="https://livesession.io/features/" target="_blank" rel="noopener noreferrer">
          LiveSession
        </a>{' '}
        has to offer, and discover the power of qualitative analytics once you’re at it.
      </p>
    </Container>
  );
};

const Wrapped = BlogArticle(Content);

export const frontmatter = {
  title: 'Why my conversion rate is so low? 5 main reasons',
  url: '/blog/low-conversion-rate',
  description:
    'Have you noticed a sudden drop in your conversion rate or has it been low to begin with? Fortunately, there are ways to fix it.',
  author,
  img,
  imgSocial: img,
  date: '2019-11-07',
  category: '',
  imgStyle: { borderBottom: '1px solid rgba(0,0,0,0.125)' },
  group: 'Analytics',
};

export default () => (
  <Wrapped
    title={frontmatter.title}
    category={frontmatter.category}
    description={frontmatter.description}
    date={frontmatter.date}
    author={frontmatter.author}
    url={frontmatter.url}
    img={frontmatter.img}
    imgSocial={process.env.GATSBY_HOST + frontmatter.img}
    group={frontmatter.group}
    descriptionSocial="In fact, there are many possible explanations for a low conversion rate - and here are the 5 most common issues that you’re most likely struggling with as well. "
  />
);
